enum Service {
  HOME = "Wohnbauten",
  ROOF = "Dächer",
  CORPORATE = "Hallenbau",
  CHANGES = "Umbauten",
  FACADE = "Fassaden",
  INSIDE = "Innenausbau"
}

enum Projects {
  HOME = "Einfamilienhäuser",
  KINDER = "Kinderhäuser",
  ARCHITECTURE = "Objektbau",
  RENOVATION = "Sanierungen"
}

enum Status {
  DEFAULT,
  LOADING,
  ERROR,
  SUCCESS
}

export { Service, Projects, Status };
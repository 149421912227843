import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import Contact from '../components/Contact'
import Hero from '../components/Hero'
import ServiceContent from '../components/ServiceContent'
import ServicePicker from '../components/ServicePicker'
import Footer from '../components/utility/Footer'
import Navbar from '../components/utility/Navbar'
import heroImage from "../images/haus-01.jpg";
import { Service } from "../types/shared";
import { ServiceContext } from '../utility/context'

const heroContent = {
  image: heroImage,
  alt: "Ein mehrstöckiges Holzhaus mit großem Garten",
  headline: "Holz –<br/> ein wahrhaft<br/> einzigartiger Baustoff",
  text: "Es gibt viele Materialien, um ein sympathisches und dauerhaftes Zuhause zu bauen. Aber kein anderes verbindet so umfassende positive Eigenschaften: Nachhaltigkeit, Leichtigkeit, Festigkeit, Isolation und Oberflächenwärme, Natürlichkeit und eine überragende CO2‐Bilanz.<br/><br/> Unsere Erfahrung aus 4 Generationen Zimmermeister der Zimmerei Müller bringen wir genauso ein wie unser aktuelles Ingenieurwissen, um diesen Baustoff auch in Verbindung mit anderen in Ihrem Projekt optimal und dauerhaft einzusetzen."
}

const LeistungenPage = () => {
  const isBrowser = typeof window !== "undefined";
  const [currentService, setCurrentService] = useState(Service.HOME);

  const convertSearchToState = (search: string) => {
    if (Object.values(Service).includes(search as Service)) {
      return search as Service;
    }
    else {
      return Service.HOME;
    }
  }

  useEffect(() => {
    if (isBrowser) {
      const searchParams = new URLSearchParams(window.location.search);
      if (searchParams.has('service')) {
        const service = searchParams.get('service');
        if (service) {
          setCurrentService(convertSearchToState(service));
        }
      }
    }
  }, [])



  return (
    <>
      <Helmet title="Zimmerei Müller - Leistungen">
        <meta property="og:title" content="Zimmerei Müller - Leistungen" />
        <meta
          property="og:description"
          content="Es gibt viele Materialien, um ein sympathisches und dauerhaftes Zuhause zu bauen. Aber kein anderes verbindet so umfassende positive Eigenschaften: Nachhaltigkeit, Leichtigkeit, Festigkeit, Isolation und Oberflächenwärme, Natürlichkeit und eine überragende CO2‐Bilanz."
        />
        <meta property="og:url" content="https://holzbaumueller.de/leistungen" />
        <meta
          name="description"
          content="Es gibt viele Materialien, um ein sympathisches und dauerhaftes Zuhause zu bauen. Aber kein anderes verbindet so umfassende positive Eigenschaften: Nachhaltigkeit, Leichtigkeit, Festigkeit, Isolation und Oberflächenwärme, Natürlichkeit und eine überragende CO2‐Bilanz."
        />
      </Helmet>
      <Navbar />
      <main>
        <Hero image={heroContent.image} headline={heroContent.headline} text={heroContent.text} alt={heroContent.alt} />
        <ServiceContext.Provider value={{ service: currentService, setService: setCurrentService }}>
          <ServicePicker />
          <ServiceContent />
        </ServiceContext.Provider>
        <Contact />
      </main>
      <Footer />
    </>
  )
}

export default LeistungenPage
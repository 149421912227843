import React from 'react'
import homeIcon from "../images/haus.svg";
import roofIcon from "../images/roof.svg";
import corporateIcon from "../images/corporate.svg";
import changesIcon from "../images/changes.svg";
import facadeIcon from "../images/facade.svg";
import insideIcon from "../images/inside.svg";

import { Service } from "../types/shared";

const serviceData = [
  {
    isPlaceholder: false,
    selection: Service.HOME,
    icon: homeIcon,
    alt: "Ein Haus",
    text: <span className='service-text'>Holzhäuser</span>
  },
  {
    isPlaceholder: false,
    selection: Service.ROOF,
    icon: roofIcon,
    alt: "Ein Dach",
    text: <span className='service-text'>Dachkonstruktionen</span>
  },
  {
    isPlaceholder: false,
    selection: Service.CORPORATE,
    icon: corporateIcon,
    alt: "Ein Hochhaus",
    text: <span className='service-text'>Hallenbau</span>
  },
  {
    isPlaceholder: true,
    selection: undefined,
    icon: undefined,
    text: undefined
  },
  {
    isPlaceholder: true,
    selection: undefined,
    icon: undefined,
    text: undefined
  },
  {
    isPlaceholder: false,
    selection: Service.CHANGES,
    icon: changesIcon,
    alt: "Veränderung",
    text: <span className='service-text'>Umbau</span>
  },
  {
    isPlaceholder: false,
    selection: Service.FACADE,
    icon: facadeIcon,
    alt: "Ein Haus mit Fassade",
    text: <span className='service-text'>Fassaden</span>
  },
  {
    isPlaceholder: false,
    selection: Service.INSIDE,
    icon: insideIcon,
    alt: "Skalierung",
    text: <span className='service-text'>Innenausbau</span>
  }
]

export { serviceData }
import React, { useContext } from 'react'
import NavigationSwiper from './utility/NavigationSwiper'
import home2Image from "../images/haus-02.jpg";
import home3Image from "../images/haus-03.jpg";
import home4Image from "../images/haus-04.jpg";
import heroImage from "../images/haus-01.jpg";
import { ServiceContext } from '../utility/context';
import { Service } from '../types/shared';
import homeIcon from "../images/haus.svg";
import roofIcon from "../images/roof.svg";
import corporateIcon from "../images/corporate.svg";
import changesIcon from "../images/changes.svg";
import facadeIcon from "../images/facade.svg";
import insideIcon from "../images/inside.svg";
import roof1Image from "../images/cad-dach-01.jpg";
import roof2Image from "../images/cad-dach-02.jpg";
import roof3Image from "../images/cad-dach-03.jpg";
import roof4Image from "../images/cad-dach-04.jpg";
import hall1Image from "../images/halle-01.jpg";
import hall2Image from "../images/halle-02.jpg";
import hall3Image from "../images/halle-03.jpg";
import hall4Image from "../images/ihb-01.jpg";
import change1Image from "../images/umbau-01.jpg";
import change2Image from "../images/umbau-02.jpg";
import change3Image from "../images/umbau-03.jpg";
import change4Image from "../images/umbau-04.jpg";
import facade01 from "../images/fassade-01.jpg";
import facade02 from "../images/fassade-02.jpg";
import facade03 from "../images/fassade-03.jpg";
import facade04 from "../images/fassade-04.jpg";
import inside1Image from "../images/ausbau-01.jpg";
import "../style/components/servicecontent.scss";
import FadeInSection from './utility/FadeInSection';

const contentData = [
  {
    id: Service.HOME,
    icon: homeIcon,
    alt: "Ein Haus",
    headline: "Holzhäuser",
    text: "...sind ja längst kein Geheimtipp mehr für Nullenergie‐ und Naturenthusiasten. Und sicher wissen Sie längst, dass Holzbau auf dem Stand der Technik die Probleme und Kinderkrankheiten aus dem letzten Jahrhundert technisch im Griff und wirklich überwunden hat. Über Hellhörigkeit, hohen Pflegeaufwand, Schadstoffbelastungen, Überhitzung im Sommer und Zugerscheinungen im Winter brauchen Sie sich also keine besonderen Sorgen zu machen, wenn Sie eine kompetente und qualitätsorientierte Fachfirma beauftragen.<br/><br/> Gleichzeitig bieten wir Ihnen fast die gleiche Gestaltungsfreiheit wie bei einem vom Architekten entworfenen Stahlbetonbau. Jedes unserer Häuser ist ein Unikat, aus einer Hand und mit umfassender Gewährleistung und Service. Und lassen Sie sich überraschen, wie kostengünstig das möglich ist – auch weil wir uns statt einer großen Marketingoffensive ganz auf diese Internetseite beschränken.",
    images: [{ image: home2Image, alt: "Ein freistehenden mehrstöckiges Haus mit großem Garten" }, { image: home3Image, alt: "Mehrstöckiges Haus mit Garage" }, { image: home4Image, alt: "Ein freistehendes Haus mit großem Garten" }]
  },
  {
    id: Service.ROOF,
    icon: roofIcon,
    alt: "Ein Dach eines Hauses",
    headline: "Dachkonstruktionen",
    text: "<span class='cite'>Im Winter, wenn liegt Schnee und Eis,<br/> ich wette, dass er dann bald weiß,<br/> und fühlt, dass man den Zimmermann<br/> auf Erden nicht entbehren kann.</span><br/> So geht es dem Bauherrn in einem bekannten Richtspruch. Oder, wie der Bauphysiker sagen würde: Bauen ist der ständige Kampf gegen Wasser und Feuchtigkeit. Erste Aufgabe unserer Dächer ist daher der dauerhafte Schutz des gesamten Bauwerks vor Wind und Wetter. Und was so banal klingt ist deshalb noch lange nicht immer ganz einfach. Denn natürlich sollen Dächer heute noch viel mehr sein und lassen nicht selten das Gebäude zu einem Schmuckstück und die oberste Wohnung zur schönsten im ganzen Gebäude werden.<br/><br/> Wir machen komplette Dächer, außen aus Ziegeln oder Dachsteinen, aus Glas oder Polycarbonat, aus Sandwichplatten, Trapezblech oder Faserzement, als Flachdach, Gründach oder Dachterrasse, als Carport, Überdachung oder Hallendach, mit Gauben, Rundungen, Dachfenstern und allem, was Sie mögen, inklusive der langen Liste möglicher Innengestaltung. Wir planen alles im 3D‐CAD und Sie sehen vorher genau, wie es am Ende aussehen wird.",
    images: [{ image: roof1Image, alt: "3D-Modell eines Holzdachs für ein kleines Haus" }, { image: roof2Image, alt: "3D-Modell eines Holzdachs für einen Gebäudekomplex" }, { image: roof3Image, alt: "Verschieden Schichten eine Holzdachs" }, { image: roof4Image, alt: "3D-Modell der Grunkonstruktion eines Holzdachs" }]
  },
  {
    id: Service.CORPORATE,
    icon: corporateIcon,
    alt: "Ein hohes Gebäude",
    headline: "Hallenbau",
    text: "Falls Sie jetzt einen Generalunternehmer für ein paar tausend Quadratmeter Gewerbeobjekt suchen – dann können wir Ihnen vielleicht jemanden empfehlen, uns wäre das doch eine Nummer zu groß. Und das Selbe gilt leider, wenn Ihre Baustelle mehr als eine Stunde Fahrzeit von uns entfernt liegt.<br/><br/> Denken Sie aber eher an einen freundlichen Holzbau oder Ausbau für Ihr Büro, Ihre Verkaufsräume, Ihre Produktionshalle oder einen Umbau. Dann sind wir womöglich genau der Richtige und legen uns gerne für Sie ins Zeug. Auch weitgespannte Tragwerke, Verbundbauweisen und Aufstockungen entwickeln, planen und bauen wir gerne.<br/><br/> Holzbau ist schnell, flexibel, ökologisch, modern, sympathisch und sehr oft sehr kostengünstig. Lassen Sie sich einfach einmal kostenlos von uns beraten.",
    images: [{ image: hall1Image, alt: "Baubeginn eines Holzdachs einer Halle" }, { image: hall2Image, alt: "Außenkronstruktion des Holzdachs der Halle" }, { image: hall3Image, alt: "Ein Haus mit Holzstämmen als Dachhalter" }, { image: hall4Image, alt: "Baubeginn eines Holzdachs einer Halle" }]
  },
  {
    id: Service.CHANGES,
    icon: changesIcon,
    headline: "Umbau",
    alt: "Ein abstraktes Icon für Focus",
    text: "Eines unserer Markenzeichen ist die perfekte Planung, am liebsten nach einem Aufmaß mit Lasertachymeter und sorgfältiger Ausarbeitung im 3D‐CAD. Bei kleineren Umbauarbeiten kann es aber durchaus sein, dass Sie besser beraten sind, wenn wir bei den Feinheiten einfach vor Ort und ein Schritt nach dem anderen entscheiden, wie am besten Ihre Wünsche umzusetzen und das Schöne mit dem Machbaren zu verbinden ist.<br/><br/> Dafür stehen Ihnen immer auch vor Ort unsere erfahrenen Meister und ausgebildeten und erfahrenen Zimmerer als kompetente Partner mit Rat und Tat zur Verfügung.",
    images: [{ image: change1Image, alt: "Baubeginn bei einem Einfamilienhaus" }, { image: change2Image, alt: "Einfamilienhaus nach Fertigstellung des Umbaus" }, { image: change3Image, alt: "Ein Haus mit Gerüst während des Umbaus" }, { image: change4Image, alt: "Ein Anbau an ein Haus aus Holz" }]
  },
  {
    id: Service.FACADE,
    icon: facadeIcon,
    headline: "Fassaden",
    alt: "Ein Haus mit Fassade",
    text: "Der einen Hälfte unserer Häuser sieht man es gar nicht an, dass nicht nur das Tragwerk aus Holz besteht sondern auch die meisten Flächen, Verkleidungen und Dämmstoffe. Denn die heutigen Holzfaserplatten sind ein einwandfreier Untergrund schlichte, elegante und zeitlose Putzfassaden. Und wenn man das Ganze diffusionsoffen, sozusagen atmungsaktiv baut, dann ist das eine kostengünstige und dauerhafte Lösung.<br/><br/> Für den echten Holzhauscharakter bieten wir Ihnen aber auch die ganze Vielfalt an Holzfassaden, senkrecht und waagrecht, geschuppt, gefalzt, genutet oder teiloffen, aus heimischen Hölzern wie Lärche und Douglasie, aus langsam gewachsener nordischer Ware, aus besonders behandelten Hölzer für besondere Dauerhaftigkeit und in allen Formen und Farben der Behandlung mit Lasuren, Ölen, Deckfarben und auch Feuer – wenn Sie mögen!<br/><br/> Und für besonders beanspruchte Bereiche dazu alle Lösungen aus Faserzement, HPL, Aluminium, Steinwolle und so weiter.",
    images: [{ image: facade01, alt: "Die Fassade eine Holzhauses mit breiten Fenstern" }, { image: facade02, alt: "Holzfassade mit einem Fenster über mehrere Stockwerke" }, { image: facade03, alt: "Ein freistehendes Haus mit Garten" }, { image: facade04, alt: "Closeup einer Holzfassade" }]
  },
  {
    id: Service.INSIDE,
    icon: insideIcon,
    headline: "Innenausbau",
    alt: "Ein abstraktes Icon für Skalierung",
    text: "Sie können zum Entspannen in die Natur gehen. oder sich jeden Tag von etwas Natur umgeben lassen – direkt dort, wo Sie sich wohl fühlen, in Ihrem Zuhause.<br/><br/> Verraten Sie uns, wie Sie Ihre Komfortzone denken – wir bauen sie als Holz‐Zone nach Ihren Wünschen und stehen Ihnen mit Rat und Tat auf Augenhöhe zur Seite.",
    images: [{ image: inside1Image, alt: "Ausbau eines alten Holzhauses mit neuem Dach" }]
  }
]

const ServiceContent = () => {
  const service = useContext(ServiceContext);

  const generateContent = (currentService: Service) => {
    const data = contentData.find(content => content.id === currentService);

    return (
      <>
        <div className='service-content-container'>
          <FadeInSection>
            <div className='service-content-headline-container'>
              <img className='service-content-headline-icon' src={data?.icon} alt={data?.alt} />
              <h2 className='service-content-headline' dangerouslySetInnerHTML={{ __html: data?.headline ?? "" }}></h2>
            </div>
            <p className='service-content-text' dangerouslySetInnerHTML={{ __html: data?.text ?? "" }}></p>
          </FadeInSection>
        </div>
        <FadeInSection>
          <div className='swiper-line-wrapper'>
            <NavigationSwiper images={data?.images ?? []} />
            <hr className='tangente-line-swiper' />
          </div>
        </FadeInSection>
      </>
    );
  }

  return (
    <section id="service-content">
      <div className='wrapper'>
        {generateContent(service?.service ?? Service.HOME)}
        <hr className='tangente-line' />
      </div>
      <div className='color-extension'>
      </div>
    </section>
  )
}

export default ServiceContent
import React, { useContext } from 'react'
import "../style/components/services.scss";
import { serviceData } from '../data/service';
import { ServiceContext } from '../utility/context';
import { Service } from '../types/shared';

const ServicePicker = () => {
  const serviceContext = useContext(ServiceContext);

  const setURLRoute = (currentService: Service) => {
    const isBrowser = typeof window !== "undefined";
    if (isBrowser) {
      window.history.replaceState(null, '', `/leistungen?service=${currentService}`);
    }
  }

  return (
    <section id="services" className='lightgray picker'>
      <div className='wrapper'>
        <div className='services-grid'>
          {
            serviceData.map((service, i) =>
              <div className={service.isPlaceholder ? "placeholder" : ""} key={`service-${i}`}>
                {!service.isPlaceholder && service.selection &&
                  <button className={`service-card ${service.selection === serviceContext?.service ? "active" : ""}`} onClick={() => {
                    serviceContext?.setService(service.selection)
                    setURLRoute(service.selection);
                  }}>
                    <div className='service-card-icon-container'>
                      <img className='service-icon' src={service.icon} alt={service.alt} />
                      {i === 0 && <hr className='horizontal-line' />}
                    </div>
                    <span className='service-text'>{service.text}</span>
                  </button>
                }
              </div>
            )
          }
        </div>
      </div>
    </section>
  )
}

export default ServicePicker
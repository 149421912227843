import React from 'react'
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import "swiper/scss";
import arrowIcon from "../../images/arrow.svg";

const NavigationSwiper = (props: { images: { image: string, alt: string }[] }) => {

  const navigationPrevRef = React.useRef(null);
  const navigationNextRef = React.useRef(null);

  return (
    <Swiper navigation={{
      prevEl: ".go-back",
      nextEl: ".go-next",
    }} modules={[Navigation]} className="custom-swiper">
      {props.images.map((image: { image: string, alt: string }, i: number) =>
        <SwiperSlide key={`workflow-slide-${i}`} className="swiper-slide">
          <img className='swiper-image' src={image.image} alt={image.alt} loading='lazy' />
        </SwiperSlide>
      )}
      <div className='navigation-container'>
        <div className='navigation back go-back' ref={navigationPrevRef}>
          <img className='back-image' src={arrowIcon} alt="Ein Pfeil zum vorherigen Bild" />
        </div>
        <div className='navigation next go-next' ref={navigationNextRef}>
          <img className='next-image' src={arrowIcon} alt="Ein Pfeil zum nächsten Bild" />
        </div>
      </div>
    </Swiper>
  )
}

export default NavigationSwiper